<template>
    <div>
        <v-form @submit.prevent="agregar">
            <v-container lighten-5>

                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-card>
                            <v-card-actions>
                                <div class="flex-grow-1"></div>
                                <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="formPaciente">
                    <v-col cols="12" sm="12" md="12">
                        <span class="headline green--text">DATOS DEL PACIENTE</span>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-card>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            Datos del paciente
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field outlined v-on:keyup="$data.formPaciente.tblConfPersonasnaturales[0].copnNombre1 = $event.target.value.toUpperCase()" :error="$v.formPaciente.tblConfPersonasnaturales.$each[0].copnNombre1.$error" label="Primer Nombre" v-model="formPaciente.tblConfPersonasnaturales[0].copnNombre1"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field outlined v-on:keyup="$data.formPaciente.tblConfPersonasnaturales[0].copnNombre2= $event.target.value.toUpperCase()" label="Segundo Nombre" v-model="formPaciente.tblConfPersonasnaturales[0].copnNombre2"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field outlined v-on:keyup="$data.formPaciente.tblConfPersonasnaturales[0].copnApellido1 = $event.target.value.toUpperCase()" :error="$v.formPaciente.tblConfPersonasnaturales.$each[0].copnApellido1.$error" label="Primer Apellido" v-model="formPaciente.tblConfPersonasnaturales[0].copnApellido1"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field outlined v-on:keyup="$data.formPaciente.tblConfPersonasnaturales[0].copnApellido2 = $event.target.value.toUpperCase()" label="Segundo Apellido" v-model="formPaciente.tblConfPersonasnaturales[0].copnApellido2"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined label="Identificacion" v-model="formPaciente.copeIdentificacion" readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-autocomplete outlined v-model="formPaciente.copeTipoIdentificacion" :items="tipoidentificacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de identificacion" readonly></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined v-on:keyup="$data.formPaciente.copeTelefono = $event.target.value.toUpperCase()" label="Telefono" v-model="formPaciente.copeTelefono"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined v-on:keyup="$data.formPaciente.copeEmail = $event.target.value.toUpperCase()" label="Email" v-model="formPaciente.copeEmail"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined v-on:keyup="$data.formPaciente.copeDireccion = $event.target.value.toUpperCase()" label="Direccion" v-model="formPaciente.copeDireccion"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-autocomplete outlined v-model="formPaciente.copeTipoPais" :error="$v.formPaciente.copeTipoPais.$error" :items="tipopais" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Pais" clearable @change="getDepa()"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" v-if="formPaciente.copeTipoPais">
                                            <v-autocomplete outlined v-model="formPaciente.copeTipoDepartamento" :error="$v.formPaciente.copeTipoDepartamento.$error" :items="tipodepartamento" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Departamento" clearable @change="getMuni()"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" v-if="formPaciente.copeTipoDepartamento">
                                            <v-autocomplete outlined v-model="formPaciente.copeTipoMunicipio" :error="$v.formPaciente.copeTipoMunicipio.$error" :items="tipomunicipio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Municipio" clearable></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row>

                                        <v-col cols="12" sm="12" md="3">
                                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field outlined v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento" label="Fecha de nacimiento" prepend-icon="event" v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker locale="ES" v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento" :error="$v.formPaciente.tblConfPersonasnaturales.$each[0].tblConfPersonaspacientes.$each[0].coppFechanacimiento.$error" @input="menu = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="3">
                                            <v-autocomplete outlined v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPaisnacieminto" :items="tipopaisn" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Pais de nacimiento" clearable @change="getDepan()"></v-autocomplete>

                                        </v-col>

                                        <v-col cols="12" sm="12" md="3" v-if="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPaisnacieminto">
                                            <v-autocomplete outlined v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoDepartamentonacimiento" :items="tipodepartamenton" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Departamento de nacimiento" clearable @change="getMunin()"></v-autocomplete>

                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" v-if="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoDepartamentonacimiento">
                                            <v-autocomplete outlined v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoMunicipionacimiento" :items="tipomunicipion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Municipio de nacimiento" clearable></v-autocomplete>

                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field outlined v-on:keyup="$data.formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio = $event.target.value.toUpperCase()" label="Barrio" v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="3">
                                            <v-autocomplete outlined v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoZona" :items="tipozona" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Zona" clearable></v-autocomplete>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="3">
                                            <v-autocomplete outlined v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPoblacion" :items="tipopoblacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Poblacion" clearable></v-autocomplete>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="3">
                                            <v-autocomplete outlined v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoNiveleducativo" :items="tiponvleducacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Nivel educativo" clearable></v-autocomplete>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="3">
                                            <v-autocomplete outlined v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoOcupacion" :items="tipoocupacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Ocupacion" clearable></v-autocomplete>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="3">
                                            <v-autocomplete outlined v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoSexo" :items="tiposexo" :error="$v.formPaciente.tblConfPersonasnaturales.$each[0].tblConfPersonaspacientes.$each[0].coppTipoSexo.$error" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Sexo" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-autocomplete outlined v-model="formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoEstadocivil" :items="tipoestadocivil" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado civil" clearable></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="formEmpleado">
                    <v-col cols="12" sm="12" md="12">
                        <span class="headline green--text">DATOS DEL EMPLEADO</span>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-card>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            Datos del empleado
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field outlined v-on:keyup="$data.formEmpleado.tblConfPersonasnaturales[0].copnNombre1 = $event.target.value.toUpperCase()" :error="$v.formEmpleado.tblConfPersonasnaturales.$each[0].copnNombre1.$error" label="Primer Nombre" v-model="formEmpleado.tblConfPersonasnaturales[0].copnNombre1"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field outlined v-on:keyup="$data.formEmpleado.tblConfPersonasnaturales[0].copnNombre2= $event.target.value.toUpperCase()" label="Segundo Nombre" v-model="formEmpleado.tblConfPersonasnaturales[0].copnNombre2"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field outlined v-on:keyup="$data.formEmpleado.tblConfPersonasnaturales[0].copnApellido1 = $event.target.value.toUpperCase()" :error="$v.formEmpleado.tblConfPersonasnaturales.$each[0].copnApellido1.$error" label="Primer Apellido" v-model="formEmpleado.tblConfPersonasnaturales[0].copnApellido1"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field outlined v-on:keyup="$data.formEmpleado.tblConfPersonasnaturales[0].copnApellido2 = $event.target.value.toUpperCase()" label="Segundo Apellido" v-model="formEmpleado.tblConfPersonasnaturales[0].copnApellido2"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined label="Identificacion" readonly v-model="formEmpleado.copeIdentificacion"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-autocomplete outlined v-model="formEmpleado.copeTipoIdentificacion" readonly :items="tipoidentificacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de identificacion"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined v-on:keyup="$data.formEmpleado.copeTelefono = $event.target.value.toUpperCase()" label="Telefono" v-model="formEmpleado.copeTelefono"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined v-on:keyup="$data.formEmpleado.copeEmail = $event.target.value.toUpperCase()" label="Email" v-model="formEmpleado.copeEmail"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined v-on:keyup="$data.formEmpleado.copeDireccion = $event.target.value.toUpperCase()" label="Direccion" v-model="formEmpleado.copeDireccion"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-autocomplete outlined v-model="formEmpleado.copeTipoPais" :items="tipopais" :error="$v.formEmpleado.copeTipoPais.$error" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Pais" clearable @change="getDepa(formEmpleado.copeTipoPais)"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" v-if="formEmpleado.copeTipoPais">
                                            <v-autocomplete outlined v-model="formEmpleado.copeTipoDepartamento" :items="tipodepartamento" :error="$v.formEmpleado.copeTipoDepartamento.$error" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Departamento" clearable @change="getMuni(formEmpleado.copeTipoDepartamento)"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" v-if="formEmpleado.copeTipoDepartamento">
                                            <v-autocomplete outlined v-model="formEmpleado.copeTipoMunicipio" :error="$v.formEmpleado.copeTipoMunicipio.$error" :items="tipomunicipio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Municipio" clearable></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-autocomplete outlined v-model="formEmpleado.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprTipoCargo" :error="$v.formEmpleado.tblConfPersonasnaturales.$each[0].tblConfPersonasempleados.$each[0].coprTipoCargo.$error" :items="tipocargo" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Cargo" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined label="Pie de firma" v-model="formEmpleado.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprPieFirma"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                        </v-card>

                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <span class="headline green--text">DATOS DEL USUARIO</span>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-card>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            Datos del usuario
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field type="password" v-model="formUser.password_hash" label="password" outlined required />
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field type="password" v-model="formUser.repassword" label="re password" outlined required />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

            </v-container>
        </v-form>
    </div>
</template>

<script>
import {
  required
}
from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      user: this.$cookies.get("user"),
      url: "sistema/personas/",
      token: this.$cookies.get("token"),

      formPaciente: null,
      formEmpleado: null,
      tipoidentificacion: [],
      tipodepartamento: [],
      tipomunicipio: [],
      tipopais: [],
      tipocargo: [],
      tipodepartamenton: [],
      tipomunicipion: [],
      tipopaisn: [],
      tipozona: [],
      tipopoblacion: [],
      tiponvleducacion: [],
      tipoocupacion: [],
      tiposexo: [],
      tipoestadocivil: [],
      formUser: {
        //email: "",
        password_hash: "",
        repassword: ""
      },
      rules: {
        password: [
          v => !!v || "requiredo",
          v =>
          (v && v.length > 2) || "La contraseña debe tener almenos 4 digitos"
        ],
        repassword: [
          v => !!v || "requiredo",
          v =>
          (v && v.length > 2) || "La contraseña debe tener almenos 4 digitos"
        ],
      },

    }
  },
  validations: {
    formEmpleado: {
      copeTipoPais: {
        required
      },

      copeTipoDepartamento: {
        required
      },

      copeTipoMunicipio: {
        required
      },
      tblConfPersonasnaturales: {
        $each: {
          copnNombre1: {
            required
          },
          copnApellido1: {
            required
          },
          tblConfPersonasempleados: {
            $each: {
              coprTipoCargo: {
                required
              },
            }
          }
        }
      }
    },
    formPaciente: {

      copeTipoPais: {
        required,
      },

      copeTipoDepartamento: {
        required,
      },

      copeTipoMunicipio: {
        required,
      },
      tblConfPersonasnaturales: {
        $each: {
          copnNombre1: {
            required,
          },
          copnApellido1: {
            required,
          },
          tblConfPersonaspacientes: {
            $each: {
              coppFechanacimiento: {
                required,
              },
              /*  coppTipoZona: {
                    required,
                },
                  coppTipoPaisnacieminto: {
                      required,
                  },
                  coppTipoDepartamentonacimiento: {
                      required,
                  },
                  coppTipoMunicipionacimiento: {
                      required,
                  },*/
              coppTipoSexo: {
                required,
              },

            }

          },

        }
      },

    },
  },

  created: async function() {
    this.$store.commit('setCargaDatos', true);
    if (this.$cookies.get('user').persona) {
      if (this.$cookies.get('user').role.indexOf('PACIENTE') > -1) {
        this.formPaciente = await this.$apiService.index(`${this.url}view/${this.$cookies.get('user').persona}/pacientes`).then(data => data);

        this.tipopaisn = this.tipopais = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPAIS").then(data => data);
        this.tipoidentificacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOIDENT").then(data => data);

        this.tipozona = await this.$apiService.index("sistema/maestra/index/TBL_TIPOZONA").then(data => data);
        this.tipopoblacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPOBLACION").then(data => data);
        this.tiponvleducacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPONVLEDUCATIVO").then(data => data);
        this.tipoocupacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOOCUPACION").then(data => data);
        this.tiposexo = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSEXO").then(data => data);
        this.tipoestadocivil = await this.$apiService.index("sistema/maestra/index/TBL_TIPOESTADOCIVIL").then(data => data);
        await this.getDepa();
        await this.getMuni();
        await this.getDepan();
        await this.getMunin();
      } else {
        this.formEmpleado = await this.$apiService.index(`${this.url}view/${this.$cookies.get('user').persona}/empleados`).then(data => data);
        this.tipoidentificacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOIDENT").then(data => data);
        this.tipopais = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPAIS").then(data => data);
        this.tipocargo = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCARGO").then(data => data);
        await this.getDepa();
        await this.getMuni();
      }

    }
    this.$store.commit('setCargaDatos', false);

  },
  computed: {

  },

  methods: {
    async getDepa() {
      this.tipodepartamento = await this.$apiService.index("sistema/maestra/index/TBL_TIPODEPART/" + ((this.formPaciente) ? this.formPaciente.copeTipoPais : this.formEmpleado.copeTipoPais)).then(data => data);
    },
    async getMuni() {
      this.tipomunicipio = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMUNICIPIO/" + ((this.formPaciente) ? this.formPaciente.copeTipoDepartamento : this.formEmpleado.copeTipoDepartamento)).then(data => data);
    },
    async getDepan() {
      this.tipodepartamenton = await this.$apiService.index("sistema/maestra/index/TBL_TIPODEPART/" + this.formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPaisnacieminto).then(data => data);
    },
    async getMunin() {
      this.tipomunicipion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMUNICIPIO/" + this.formPaciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoDepartamentonacimiento).then(data => data);
    },
    async agregar() {
      if (this.$cookies.get('user').persona) {
        let condicion = this.$cookies.get('user').role.indexOf('PACIENTE') > -1;
        (condicion) ? this.$v.formPaciente.$touch(): this.$v.formEmpleado.$touch();
        if ((condicion) ? !this.$v.formPaciente.$error : !this.$v.formEmpleado.$error) {
          try {
            let id = (condicion) ? this.formPaciente.copeId : this.formEmpleado.copeId;
            let data = await this.$apiService.update(this.url + "update/" + id + ((condicion) ? "/pacientes" : "/empleados"), (condicion) ? this.formPaciente : this.formEmpleado).then(data => data);
            if (data) {
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } catch (error) {
            console.log(error);
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }
        }
      }
      if (this.formUser.password_hash && this.formUser.repassword ) {
        if ( this.formUser.password_hash == this.formUser.repassword) {
          try {
            let data = await this.$apiService.update("sistema/user/updatePassword/" + this.$cookies.get('user').id + "/", this.formUser).then(data => data);
            if (data) {
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } catch (error) {
            console.log(error);
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }
        } else {
          this.$swal.fire({
            title: "Cuidado!",
            text: "Los contraseñas deben ser iguales",
            icon: "warning"
          });
        }
      }

    },
  }
}

</script>
