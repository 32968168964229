<template lang="">
    <v-app>
        <v-app-bar elevation="0" absolute :color="$vuetify.theme.dark? '':'white'" app v-if="$cookies.isKey('user')">
            <v-app-bar-nav-icon color="primary" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="primary--text">{{entidad}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="primary" class="ml-1" @click="$vuetify.theme.dark=!$vuetify.theme.dark">
                <v-icon>mdi-moon-waning-crescent</v-icon>
            </v-btn>
            <v-menu offset-y transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn class="toolbar-items" color="primary" v-on="on" icon>
                        <v-badge color="error" overlap>
                            <template v-slot:badge>0</template>
                            <v-icon>notifications_none</v-icon>
                        </v-badge>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">Notificaciones</v-list-item-title>
                            <v-list-item-subtitle>0 notificaiones</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item>
                        <v-list-item-title>No tienes nuevas notificaciones</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn text x-small color="primary" to="/perfil">{{$cookies.get('user').username}}</v-btn>
            <v-btn icon @click="logout" color="primary">
                <v-icon class="material-icons-two-tone">mdi-logout</v-icon>
            </v-btn>
            <v-btn icon @click.stop="setDrawerRight(!drawerRight)" v-if=" user.role.indexOf('PACIENTE') == -1">
                <v-badge color="error" overlap>
                    <template v-slot:badge>{{countMensajes}}</template>
                    <v-icon>mdi-comment-text-multiple</v-icon>
                </v-badge>
            </v-btn>
        </v-app-bar>
        <v-main :style="($vuetify.theme.dark)?'background:#121212':'background:rgb(220 223 224)'">
            <template v-if="licencia">
                <template v-if="licencia.length > 0 && licencia.find(e =>e.comaNombrecorto == 'CON_LICENCIA') && licencia.find(e =>e.comaNombrecorto == 'FECHA_LICENCIA')">
                    <v-alert v-if="licencia.find(e =>e.comaNombrecorto == 'CON_LICENCIA').comaValor != 'SI'" class="mt-5 ml-5 mr-5" text outlined prominent color="error" icon="mdi-alert" border="left">
                        <span class="">{{licencia.find(e =>e.comaNombrecorto == 'MSJ_VENC_LICENCIA').comaValor}}</span>
                    </v-alert>
                    <v-alert v-else-if="new Date(licencia.find(e =>e.comaNombrecorto == 'FECHA_LICENCIA').comaValor).getTime() < new Date().getTime()" class="mt-5 ml-5 mr-5" text outlined prominent color="error" icon="mdi-alert" border="left">
                        <span class="">{{licencia.find(e =>e.comaNombrecorto == 'MSJ_VENC_LICENCIA').comaValor}}</span>
                    </v-alert>
                    <v-alert v-else-if="((new Date(licencia.find(e =>e.comaNombrecorto == 'FECHA_LICENCIA').comaValor).getTime() - new Date().getTime())/ 86400000) < 8" text outlined prominent class="mt-5 ml-5 mr-5" color="info" icon="mdi-information-outline" border="left">
                        <span class="">{{licencia.find(e =>e.comaNombrecorto == 'MSJ_LICENCIA').comaValor}}</span> <br>
                        <span class="caption">Este es un mensaje automatico que se lanza con una semana de anticipacion a su fecha de vencimiento.</span>
                    </v-alert>
                </template>
            </template>
            <v-fade-transition mode="out-in">
                <router-view class="" v-if="!debe || user.username == 'soporte'"></router-view>
            </v-fade-transition>
            <v-menu v-model="fab" :close-on-content-click="false" top offset-y max-height="500" :nudge-width="500" v-if=" user.role.indexOf('ENFERMERA') > -1 || user.role.indexOf('ODONTOLOGO(A)') > -1 || user.role.indexOf('MEDICO') > -1 || user.role.indexOf('ADMIN') > -1">
                <template v-slot:activator="{ on }">
                    <v-btn color="primary darken-2" fixed bottom right dark fab v-on="on" transition="slide-y-reverse-transition">
                        <v-icon v-if="fab">mdi-close</v-icon>
                        <v-icon v-else>mdi-stethoscope</v-icon>
                    </v-btn>
                </template>
                <widgetMedico v-on:cerrarWidget="fab = $event" v-if="fab" />
            </v-menu>
        </v-main>
        <v-navigation-drawer style="border:none" v-if="$cookies.isKey('user')" id="menu-izq" disable-resize-watcher hide-overlay :dark="$vuetify.theme.dark" app left v-model="drawer">
            <v-list-item class="primary pl-0 pr-0">
                <v-list-item-content>
                    <v-img :src="require('@/assets/ips tools sin fondo.png')" aspect-ratio="2" contain max-width="250" max-height="60"></v-img>
                </v-list-item-content>

                <!-- <v-list-item-content>
                <v-list-item-title class="title primary--text">IpsTools</v-list-item-title>
            </v-list-item-content> -->
            </v-list-item>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item-group v-if="permisos.length > 0">
                    <template v-for="route in $router.options.routes[0].children">
                        <v-list-item :key="route.name" active-class="cyan--text text--darken-2" :to="route" v-if="route.name == 'Inicio' || !route.desplegable && route.menu == true && (permisos.some(e => route.meta.permiso.some( a => e.child.indexOf(a ) > -1)) || route.meta.permiso.length==0) ">
                            <v-list-item-icon>
                                <v-icon class="material-icons-two-tone">{{route.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="route.meta.title" />
                        </v-list-item>
                        <v-list-group :key="route.name" active-class="cyan--text text--darken-2" not-action :prepend-icon="route.icon" v-else-if="route.desplegable  &&  route.menu == true && (permisos.some(e => route.meta.permiso.some( a => e.child.indexOf(a ) > -1)) || route.meta.permiso.length==0) ">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title v-text="route.meta.title" />
                                </v-list-item-content>
                            </template>
                            <template v-for="subroute in route.children">
                                <v-list-group no-action sub-group v-if="subroute.desplegable && subroute.menu == true && (permisos.some(e => subroute.meta.permiso.some( a => e.child.indexOf(a ) > -1)) || subroute.meta.permiso.length==0) " :key="subroute.path">
                                    <template v-slot:activator>
                                        <v-list-item-content>
                                            <v-list-item-title>{{subroute.meta.title}}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <template v-for="subsubroute in subroute.children">
                                        <v-list-item :key="subsubroute.path" v-if="subsubroute.menu == true && (permisos.some(e => subsubroute.meta.permiso.some( a => e.child.indexOf(a ) > -1)) || subsubroute.meta.permiso.length==0) " active-class="cyan--text text--darken-2" :to="subsubroute" link>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="subsubroute.meta.title"></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <v-icon class="material-icons-two-tone">{{subsubroute.icon}}</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>
                                    </template>
                                </v-list-group>
                                <v-list-item active-class="cyan--text text--darken-2" v-else-if="subroute.menu == true && (permisos.some(e => subroute.meta.permiso.some( a => e.child.indexOf(a ) > -1)) || subroute.meta.permiso.length==0) " class="cyan--text text--darken-2" :to="subroute" :key="subroute.path">
                                    <v-list-item-title v-text="subroute.meta.title"></v-list-item-title>
                                    <v-list-item-icon>
                                        <v-icon class="material-icons-two-tone">{{subroute.icon}}</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </template>
                        </v-list-group>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <Chat v-if=" user.role.indexOf('PACIENTE') == -1" />
    </v-app>
</template>

<script>
import {
  mapState
} from 'vuex';
export default {
  data() {
    return {
      permisos: [],
      drawer: true,
      licencia: null,
      entidad: null,
      debe: false,
      fab: false,
      user: this.$cookies.get("user")
    }
  },
  computed: {
    ...mapState({
      notificacion: 'notificacion',
      countMensajes: 'countMensajes',
      drawerRight: 'drawerRight',
      widgetMedico: 'widgetMedico'
    }),
  },
  async created() {
    if (this.$cookies.isKey("token") && this.$cookies.isKey("user")) {
      await this.$config.cargar();
      this.entidad = this.$cookies.get("NOMBRE");
      this.licencia = await this.$apiService.index("sistema/maestra/index/TIPO_LICENCIA").then(data => data);
      if (this.licencia) {
        if (this.licencia.length > 0) {
          if (this.licencia.find(e => e.comaNombrecorto == 'CON_LICENCIA') && this.licencia.find(e => e.comaNombrecorto == 'FECHA_LICENCIA')) {
            if ((this.licencia.find(e => e.comaNombrecorto == 'CON_LICENCIA').comaValor != 'SI') || (new Date(this.licencia.find(e => e.comaNombrecorto == 'FECHA_LICENCIA').comaValor).getTime() < new Date().getTime())) {
              this.debe = true;
            }
          }
        }
      }
      if(this.$cookies.get("user")){
      let permisos = [];
      for (const iterator of this.$cookies.get("user").role.split(',')) {
        let perm = await this.$apiService.usermenu({
          parent: iterator,userId:this.user.id
        }).then(data => data);
        permisos = permisos.concat(perm);
      }
      localStorage.setItem("permisos", JSON.stringify(permisos));
      this.permisos = permisos;
      }
      
    } else {
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
      this.$router.push('/login');
    }

  },
  methods: {
    async logout() {
      //await this.$apiService.logout(this.$cookies.get("token")).then(res => res)
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
      this.$router.push('/login');
    },
    cerrarnotificacion() {
      this.$store.commit('setNotificacion', false);
    },
    isActiveMenu(path) {
      return window.location.pathname == path;
    },
    setDrawerRight(v) {
      this.$store.commit('setDrawerRight', v);
    },
    setWidgetMedico(v) {
      this.$store.commit('setWidgetMedico', v);
    },
  }
}

</script>

<style>
html {
  background-image: linear-gradient(to right, rgb(91 35 165), rgb(12 18 41 / 95%)) !important;
}

.theme--light.v-icon,
.theme--dark.v-icon {
  opacity: 1 !important;
}

.v-data-iterator .v-toolbar,
.v-dialog .v-card__title {
  background-color: #0097A7 !important;
  /*linear-gradient(to right, rgb(69, 171, 202), rgb(14, 87, 156)) !important;*/
  color: white;
}

.active-gradient {
  /*background-image: linear-gradient(to right, rgb(69, 171, 202), rgb(14, 87, 156)) !important;*/
}

#menu-izq>.v-navigation-drawer__content::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

#menu-izq>.v-navigation-drawer__content::-webkit-scrollbar:vertical {
  width: 10px !important;
}

#menu-izq>.v-navigation-drawer__content::-webkit-scrollbar-button:increment,
#menu-izq>.v-navigation-drawer__content::-webkit-scrollbar-button {
  display: none !important;
}

#menu-izq>.v-navigation-drawer__content::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

#menu-izq>.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: #797979 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
}

#menu-izq>.v-navigation-drawer__content::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

#menu-izq>.v-navigation-drawer__content {
  scrollbar-color: rgb(255, 255, 255) rgba(0, 0, 0, 0);
  scrollbar-width: thin !important;
}

#menu-der>.scroll-converzacion::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

#menu-der>.v-navigation-drawer__content ::-webkit-scrollbar:vertical {
  width: 10px !important;
}

#menu-der>.v-navigation-drawer__content::-webkit-scrollbar-button:increment,
#menu-der>.v-navigation-drawer__content::-webkit-scrollbar-button {
  display: none !important;
}

#menu-der>.v-navigation-drawer__content::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

#menu-der>.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: #797979 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
}

#menu-der>.v-navigation-drawer__content::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

#menu-der>.v-navigation-drawer__content {
  scrollbar-color: rgb(76, 113, 159) rgb(199, 220, 242);
  scrollbar-width: thin;
}

#scroll-converzacion ::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

#scroll-converzacion::-webkit-scrollbar:vertical {
  width: 10px !important;
}

#scroll-converzacion::-webkit-scrollbar-button:increment,
#scroll-converzacion::-webkit-scrollbar-button {
  display: none !important;
}

#scroll-converzacion::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

#scroll-converzacion::-webkit-scrollbar-thumb {
  background-color: #797979 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
}

#scroll-converzacion::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

#scroll-converzacion {
  scrollbar-color: rgb(76, 113, 159) rgb(199, 220, 242);
  scrollbar-width: thin;
}

</style>
