<template>
<div v-if="user">
 <v-container fluid v-if="user.role.indexOf('ADMIN') > -1">
        <v-row justify="center">
    
            <v-col cols="12" sm="12" md="12">
    
                <v-row>
    
                    <v-col cols="12" sm="12" md="12">
    
                        <h1 class="green--text  font-weight-light">
    
                            
    
                        </h1>
    
                    </v-col>
    
                </v-row>
    
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="contratos.length">
    
                <v-card>
    
                    <v-card-text>
    
                        <v-row>
    
                            <v-col cols="12" sm="12" md="7">
    
                                <v-autocomplete outlined dense v-model="contrato" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato">
    
                                    <template v-slot:selection="data">
    
                                        {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                        
                                                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                            
                                                <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                            
                                                <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                            
                                            </v-list-item-content>
                                        </template>
                                    </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" v-if="contrato">
                            <v-autocomplete outlined dense v-model="basededato" :items="bd.filter( e => e.cocoId == this.contrato).sort( (a,b) => parseInt(b.cobdId) - parseInt(a.cobdId))" item-text="cobdId" item-value="cobdId" item-color="primary" label="Base de dato" @change="cambiarDashboard()">
                                        <template v-slot:selection="data">
                                            {{data.item.anio.comaNombrecorto +" - "+data.item.mes.comaNombrecorto}}
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="(typeof data.item !== 'object')">
                                              <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-content>
                                                
                                                    <v-list-item-title>{{data.item.anio.comaNombrecorto +" - "+data.item.mes.comaNombrecorto}}</v-list-item-title>
                                                
                                                </v-list-item-content>
                                            </template>
                                        </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
        </v-col>
         <v-col cols="12" sm="12" md="10" v-if="datosAptos.length">
            <v-simple-table>
<template v-slot:default>
    <thead>
        <tr>
            <th class="text-left">
                Total Pacientes
            </th>
            <th class="text-left">
                {{datosAptos[0].TOTAL_BASEDEDATO}}
            </th>
        </tr>
        <tr>
            <th class="text-left" colspan="4">
                Clasificaciones de los usuarios
            </th>
            <th class="text-left">
                Total
            </th>
            <th class="text-left" colspan="3">
                Analisis %
            </th>
        </tr>
        <tr>
    
            <th class="text-left">
    
                {{ 'Hombres'}}
    
            </th>
    
            <th class="text-left">
    
                {{datosAptos.find(e => e.SEXO == 'M').TOTAL_SEXO}}
    
            </th>
    
            <th class="text-left">
    
                {{'Mujeres'}}
    
            </th>
    
            <th class="text-left">
    
                {{datosAptos.find(e => e.SEXO != 'M').TOTAL_SEXO}}
    
            </th>
    
            <th class="text-left">
    
                {{datosAptos[0].TOTAL_BASEDEDATO}}
    
            </th>
    
            <th class="text-left">
    
                % {{ 'Hombres'}}
    
            </th>
    
            <th class="text-left">
    
                % {{ 'Mujeres'}}
    
            </th>
    
            <th class="text-left">
    
                % Total
    
            </th>
    
        </tr>
    
    </thead>
    
    <tbody>
    
        <tr>
    
            <td>Edad Fertil (Gestacional)</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').GESTACIONAL }}</td>
    
            <td>Edad Fertil (Gestacional)</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').GESTACIONAL }}</td>
    
            <td>{{ parseInt(datosAptos.find(e => e.SEXO == 'M').GESTACIONAL) + parseInt(datosAptos.find(e => e.SEXO != 'M').GESTACIONAL)}}</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').PORCENTAJE_GESTACIONAL}} %</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').PORCENTAJE_GESTACIONAL}} %</td>
    
            <td>{{ Math.ceil(((parseInt(datosAptos.find(e => e.SEXO == 'M').GESTACIONAL) + parseInt(datosAptos.find(e => e.SEXO != 'M').GESTACIONAL))/ parseInt(datosAptos[0].TOTAL_BASEDEDATO)) * 100) }} %</td>
    
        </tr>
    
        <tr>
    
            <td>Hipertension y Diabetes</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').HIPERTENCION_DIABETES }}</td>
    
            <td>Hipertension y Diabetes</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').HIPERTENCION_DIABETES }}</td>
    
            <td>{{ parseInt(datosAptos.find(e => e.SEXO == 'M').HIPERTENCION_DIABETES) + parseInt(datosAptos.find(e => e.SEXO != 'M').HIPERTENCION_DIABETES) }}</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').PORCENTAJE_HIPERTENCION_DIABETES}} %</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').PORCENTAJE_HIPERTENCION_DIABETES}} %</td>
    
            <td>{{ Math.ceil(((parseInt(datosAptos.find(e => e.SEXO == 'M').HIPERTENCION_DIABETES) + parseInt(datosAptos.find(e => e.SEXO != 'M').HIPERTENCION_DIABETES))/ parseInt(datosAptos[0].TOTAL_BASEDEDATO)) * 100) }} %</td>
    
        </tr>
    
        <tr>
    
            <td>Primera Infancia</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').PRIMERA_INFANCIA }}</td>
    
            <td>Primera Infancia</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').PRIMERA_INFANCIA }}</td>
    
            <td>{{ parseInt(datosAptos.find(e => e.SEXO == 'M').PRIMERA_INFANCIA) + parseInt(datosAptos.find(e => e.SEXO != 'M').PRIMERA_INFANCIA) }}</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').PORCENTAJE_PRIMERA_INFANCIA}} %</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').PORCENTAJE_PRIMERA_INFANCIA}} %</td>
    
            <td>{{ Math.ceil(((parseInt(datosAptos.find(e => e.SEXO == 'M').PRIMERA_INFANCIA) + parseInt(datosAptos.find(e => e.SEXO != 'M').PRIMERA_INFANCIA))/ parseInt(datosAptos[0].TOTAL_BASEDEDATO)) * 100) }} %</td>
    
        </tr>
    
        <tr>
    
            <td>Infancia</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').INFANCIA }}</td>
    
            <td>Infancia</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').INFANCIA }}</td>
    
            <td>{{ parseInt(datosAptos.find(e => e.SEXO == 'M').INFANCIA) + parseInt(datosAptos.find(e => e.SEXO != 'M').INFANCIA) }}</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').PORCENTAJE_INFANCIA}} %</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').PORCENTAJE_INFANCIA}} %</td>
    
            <td>{{ Math.ceil(((parseInt(datosAptos.find(e => e.SEXO == 'M').INFANCIA) + parseInt(datosAptos.find(e => e.SEXO != 'M').INFANCIA))/ parseInt(datosAptos[0].TOTAL_BASEDEDATO)) * 100) }} %</td>
    
        </tr>
    
        <tr>
    
            <td>Adolecesia</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').ADOLECENSIA }}</td>
    
            <td>Adolecesia</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').ADOLECENSIA }}</td>
    
            <td>{{ parseInt(datosAptos.find(e => e.SEXO == 'M').ADOLECENSIA) + parseInt(datosAptos.find(e => e.SEXO != 'M').ADOLECENSIA)}}</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').PORCENTAJE_ADOLECENSIA}} %</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').PORCENTAJE_ADOLECENSIA}} %</td>
    
            <td>{{ Math.ceil(((parseInt(datosAptos.find(e => e.SEXO == 'M').ADOLECENSIA) + parseInt(datosAptos.find(e => e.SEXO != 'M').ADOLECENSIA))/ parseInt(datosAptos[0].TOTAL_BASEDEDATO)) * 100) }} %</td>
    
        </tr>
    
        <tr>
    
            <td>Planificación familiar</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').PLANIFICACION }}</td>
    
            <td>Planificación familiar</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').PLANIFICACION }}</td>
    
            <td>{{ parseInt(datosAptos.find(e => e.SEXO == 'M').PLANIFICACION) + parseInt(datosAptos.find(e => e.SEXO != 'M').PLANIFICACION )}}</td>
    
            <td>{{ datosAptos.find(e => e.SEXO == 'M').PORCENTAJE_PLANIFICACION}} %</td>
    
            <td>{{ datosAptos.find(e => e.SEXO != 'M').PORCENTAJE_PLANIFICACION}} %</td>
    
            <td>{{ Math.ceil(((parseInt(datosAptos.find(e => e.SEXO == 'M').PLANIFICACION) + parseInt(datosAptos.find(e => e.SEXO != 'M').PLANIFICACION))/ parseInt(datosAptos[0].TOTAL_BASEDEDATO)) * 100) }} %</td>
    
        </tr>
    
    
    
    </tbody>
</template>
            </v-simple-table>
        </v-col>
        <v-col cols="12" sm="12" md="10" v-if="datos.length && sexo">
            <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        
                            <tr>
                        
                                <th class="text-left">
                        
                                    Total Pacientes
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    {{datos[0].TOTAL_BASEDEDATO}}
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    Total Pacientes BD anterior
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    {{datos[0].CANTIDAD_ANTERIOR}}
                        
                                </th>
                        
                            </tr>
                        
                            <tr>
                        
                                <th class="text-left" colspan="4">
                        
                                    Servicios
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    Total
                        
                                </th>
                        
                                <th class="text-left" colspan="3">
                        
                                    Analisis %
                        
                                </th>
                        
                            </tr>
                        
                            <tr>
                        
                                <th class="text-left">
                        
                                    {{ sexo == 'M' ? 'Hombres':'Mujeres' }}
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    {{datos.filter(e => e.SEXO == sexo)[0].TOTAL_SEXO}}
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    {{sexo == 'M' ? 'Mujeres':'Hombres' }}
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    {{datos.filter(e => e.SEXO != sexo)[0].TOTAL_SEXO}}
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    {{datos[0].TOTAL_BASEDEDATO}}
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    % {{ sexo == 'M' ? 'Hombres':'Mujeres' }}
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    % {{ sexo == 'M' ? 'Mujeres':'Hombres' }}
                        
                                </th>
                        
                                <th class="text-left">
                        
                                    % Total
                        
                                </th>
                        
                            </tr>
                        
                        </thead>
                        
                        <tbody>
                        
                            <tr v-for="(item,i) in datos.filter(e => e.SEXO == sexo)" :key="i">
                        
                                <td>{{ item.SERVICIO }}</td>
                        
                                <td>{{ item.POR_SEXO }}</td>
                        
                                <td>{{ (datos.filter(e => e.SEXO != item.SEXO && e.SERVICIO == item.SERVICIO).length)?datos.filter(e => e.SEXO != item.SEXO && e.SERVICIO == item.SERVICIO)[0].SERVICIO:item.SERVICIO}}</td>
                        
                                <td>{{ (datos.filter(e => e.SEXO != item.SEXO && e.SERVICIO == item.SERVICIO).length)?datos.filter(e => e.SEXO != item.SEXO && e.SERVICIO == item.SERVICIO)[0].POR_SEXO: 0 }}</td>
                        
                                <td>{{ item.TOTAL }}</td>
                        
                                <td>{{ item.PORCENTAJE_SEXO}} %</td>
                        
                                <td>{{ (datos.filter(e => e.SEXO != item.SEXO && e.SERVICIO == item.SERVICIO).length)?datos.filter(e => e.SEXO != item.SEXO && e.SERVICIO == item.SERVICIO)[0].PORCENTAJE_SEXO : 0 }} %</td>
                        
                                <td>{{ item.PORCENTAJE_TOTAL}} %</td>
                        
                            </tr>
                        
                        </tbody>
                    </template>
            </v-simple-table>
        </v-col>
       
    </v-row>
</v-container>
<v-container fluid v-else-if="user.role.indexOf('PACIENTE') > -1">
    <v-row justify="center">
        <v-col cols="12" sm="8" md="8">
            <v-alert text outlined prominent color="info" icon="mdi-information-outline">
                <span class="title"> BIENVENIDOS AL PORTAL DE PACIENTES DE IPSTools.</span><br>
                <span>En este portal podras apartar tus citas, con tus medicos de preferencia dependiendo de su disponibilidad,
                    ademas podras descargar tanto tus resultado de laboratorios como los procedimiento que te han sido realizados.</span>
            </v-alert>
        </v-col>
    </v-row>
</v-container>
</div>
   

</template>

<script>
//import * as am4core from "@amcharts/amcharts4/core";
//import * as am4charts from "@amcharts/amcharts4/charts";
//import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz.js";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";



export default {
    name: "inicio",
    components: {

    },
    data() {
        return {
            
            contratos: [],
            contrato: null,
            basededato: null,
            sexo: null,
            bd: [],
            token: this.$cookies.get("token"),
            user: this.$cookies.get("user"),
            datos: [],
            datosAptos: []
        };
    },

    created: async function() {
        
        this.$store.commit('setCargaDatos', true);
        
        this.contratos = this.contratos.filter(e => e.tipocontrato.comaNombrecorto != 'EVENTO_CON' && e.tipocontrato.comaNombrecorto != 'EVENTO_SUB');
        if (this.contratos.length > 0) {
           
            this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
            this.bd = await this.$apiService.index("sistema/basededatos/index").then(data => data);
            
            this.contrato = this.contratos[0].cocoId;
            this.basededato = this.bd.filter(e => e.cocoId == this.contrato)[this.bd.filter(e => e.cocoId == this.contrato).length - 1].cobdId;
            await this.cambiarDashboard();
        }
        this.$store.commit('setCargaDatos', false);

    },  
   /* 
   sockets: {
        connect: function() {
            if (typeof window !== 'undefined') {
                if (this.$cookies.isKey("token")) {
                    if (this.$cookies.get("user") != undefined) {
                        this.$socket.emit('addusuario', this.$cookies.get("user"));
                    }
                }
            }
        },
        reconnect: function() {
            if (typeof window !== 'undefined') {
                if (this.$cookies.isKey("token")) {
                    if (this.$cookies.get("user") != undefined) {
                        this.$socket.emit('addusuario', this.$cookies.get("user"));
                    }
                }
            }
        },
        recargargraficas: async function(data) {

            if (data.indexOf('grafica') > -1) {
                await this.cambiarDashboard();
            }

        },

    },
    */

    
    methods: {
        sexoMayor() {
            this.sexo = (this.datos.filter(e => e.SEXO == 'M').length > this.datos.filter(e => e.SEXO == 'F').length) ? 'M' : 'F';
        },
        async cambiarDashboard() {
            this.$store.commit('setCargaDatos', true);
            if (this.contrato) {
                this.datos = [];
                let reportes = await this.$apiService.index("reporte/reportes/index?offset=0&coreNombre=DASHBOARD").then(data => data);
                if (reportes) {
                    let r = reportes.rows[0];
                    r.tblConfReportesparametros.forEach(async o => {
                        o.Valor = this.basededato;
                    });
                    this.datos = await this.$apiService.create("reporte/reportes/ejecutar/", r).then(data => data);
                    this.sexoMayor();
                }

                this.datosAptos = [];
                reportes = await this.$apiService.index("reporte/reportes/index?offset=0&coreNombre=DASHBOARD_APTO").then(data => data);
                if (reportes) {
                    let r = reportes.rows[0];
                    r.tblConfReportesparametros.forEach(async o => {
                        o.Valor = this.basededato;
                    });
                    this.datosAptos = await this.$apiService.create("reporte/reportes/ejecutar/", r).then(data => data);

                }
            }
            this.$store.commit('setCargaDatos', false);

        }
    }
};
</script>
